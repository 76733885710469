import { getBrand, getFooterName, replaceStringWith, trimSp } from "@components/config/helpers";
import useGlobalSettings from "@components/_hooks/useGlobalSettings";
import useWindowSize from "@components/_hooks/useWindowSize";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import { useSelector } from "react-redux";
import FooterSocials from "./FooterSocials";


const fontSize = {
    fontSize: '14px'
}

const FooterContactUs = ({type, links}) => {
    const global_settings = useSelector(state => state.globalReducer.global_settings);
    const {isMobile} = useWindowSize();
    const {getSetting} = useGlobalSettings();
    const section_name = getFooterName(links, 'Contact Us');

    const {
        ad_sales_telephone,
        sales_telephone,
        sales_fax,
        sales_email,
        sales_address,
    } = global_settings;

    return (
        <>
            {isMobile && getSetting('footer_section_classes', 'f-size_3 mb-3 uppercase font-regular') != "" && <Typography className="f-size_3 mb-3 pl-3 mt-2 uppercase font-regular" component="h5">{getSetting('footer_contact_us_title', 'Contact Us')}</Typography>}
            {getSetting('footer_show_contact_us', '') != '' && !isMobile && (
                <Typography className={`${getSetting('footer_section_classes', 'f-size_3 mb-3 uppercase font-regular')}`} component="h5">{section_name}</Typography>
            )}
            <ul className={`font-light ${isMobile ? 'mt-5 pl-3 pr-3' : ''}`} style={fontSize}>
                <li>
                    <Link  href={`tel:${trimSp(sales_telephone)}`}>{sales_telephone}</Link>
                </li>
                <li>{sales_email}</li>
                {/* sales fax */}
                <li dangerouslySetInnerHTML={{
                            __html: replaceStringWith(sales_address, ',', ', <br/>'),
                    }}/>
            </ul>

            {
                getBrand('EH') && !isMobile && (
                    <div className="mt-2">
                        <FooterSocials include_header={false} direction="row" />
                    </div>
                )
            }
        </>
    ) 
};

export default FooterContactUs;
